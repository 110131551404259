.border-none[data-v-d7879644]:before {
  display: none;
}
.border-none[data-v-d7879644]:after {
  display: none;
}
.flex-fix[data-v-d7879644] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-d7879644]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-d7879644] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-d7879644] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-d7879644] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-d7879644] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-d7879644] {
  *zoom: 1;
}
.clear-fix[data-v-d7879644]:before,
.clear-fix[data-v-d7879644]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.recharge-page .money-item[data-v-d7879644],
.recharge-page .active[data-v-d7879644] {
  width: 3.4rem;
  height: 1.76rem;
  border-radius: 0.08rem;
}
.recharge-page .money-list[data-v-d7879644] {
  padding: 0.24rem 0.22rem 0 0.24rem;
}
.recharge-page .money-list .money-item[data-v-d7879644] {
  background: #fff;
  font-size: 0.48rem;
  color: #333;
  margin-bottom: 0.24rem;
}
.recharge-page .money-list .active[data-v-d7879644] {
  background: var(--main-color);
  font-size: 0.48rem;
  color: #fff;
}
.recharge-page .any-box[data-v-d7879644] {
  font-size: 0.32rem;
  color: #333;
  background: #fff;
  width: 100%;
  height: 1rem;
  padding: 0 0.24rem;
  margin-top: 0.16rem;
  margin-bottom: 1.88rem;
}
.recharge-page .any-box input[data-v-d7879644]::-webkit-input-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.recharge-page .any-box input[data-v-d7879644]::-moz-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.recharge-page .any-box input[data-v-d7879644]:-ms-input-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.recharge-page .any-box input[data-v-d7879644]::-ms-input-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.recharge-page .any-box input[data-v-d7879644]::placeholder {
  font-size: 0.32rem;
  color: #999;
}
.recharge-page .agreement-box[data-v-d7879644] {
  font-size: 0.28rem;
  color: #999;
  position: absolute;
  bottom: 0.24rem;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}
.recharge-page .agreement-box .agreement[data-v-d7879644] {
  color: #333;
}
